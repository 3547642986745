.video-wrapper {
  @media (min-width: 300px) {
    width: auto;
    height: 240px;
  }

  @media (min-width: 576px) {
    width: auto;
    height: 360px;
  }

  @media (min-width: 768px) {
    width: auto;
    height: 540px;
  }

  @media (min-width: 992px) {
    width: auto;
    height: 540px;
  }

  @media (min-width: 1024px) {
    width: auto;
    height: 460px;
  }

  @media (min-width: 1200px) {
    width: auto;
    height: 460px;
  }

  @media (min-width: 1400px) {
    width: auto;
    height: 560px;
  }

  @media (min-width: 1500px) {
    width: auto;
    height: 560px;
  }

  @media (min-width: 1600px) {
    width: auto;
    height: 700px;
  }
}

.type-form-view {
  width: 100%;
  height: inherit;
  border: 1px solid #ddd;
}
