.main-width {
  width: 100%;
  overflow: hidden;
  padding-left: 0px;
  height: 100%;
  padding-right: 0px;
  .left-menu {
    width: 300px;
    // background-color:rgb(31, 230, 107);
    background-color: #fff;
    height: 100%;
    position: fixed;
    top: 0px;
    left: -300px;
    transition: left 0.3s ease;
    z-index: 6000;
    box-shadow: rgba(0, 0, 0, 0.4) -5px 0px 10px;
    // .nav-item {
    //   padding-left: 10px;
    // }
    .image-logo {
      text-align: center;
      margin: 15px auto;
      margin-bottom: 18px;
      img {
        height: 100px;
      }
    }
    ul {
      list-style: none;
      padding: 0px;
      margin: 0px;
      margin-top: 23px;
    }
    li {
      float: left;
      width: 100%;
    }
    a {
      display: block;
      text-decoration: none;
      padding: 10px;
      padding-left: 15px;
      color: $primary-color;
      // font-size: 18px;
      font-weight: 500;
    }
    .active {
      // color: black;
      background-color: $secondary-color;
    }
  }
  .pop-up-left-menu {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 5000;
    background-color: rgba(0, 0, 0, 0.6);
  }
  .open {
    left: 0px;
    transition: left 0.3s ease;
  }

  .menu-nabar {
    display: none;
    .menu-hamburger {
      display: inline-block;
      width: 28px;
      margin-top: 20px;
      cursor: pointer;
      box-sizing: border-box;
      margin-left: 17px;
      .bar1,
      .bar2,
      .bar3 {
        width: 28px;
        height: 2px;
        background-color: $primary-color;
        border-radius: 10px;
        margin: 0 0 4px;
      }
    }
  }

  .left-profile {
    padding-right: 0px;
    overflow: hidden;
    background-size: 100% !important;
    background-position: bottom !important;
    background-repeat: no-repeat !important;
    background-color: rgb(244, 245, 247);
    width: 100%;
    .profile-left {
      width: 100%;
      height: 100%;
      min-height: 85vh;
      position: relative;
    }
    .nav-bar {
      width: 100%;
      z-index: 100;
      margin-top: 30px;
    }
    .nav-item {
      padding-left: 25px;
      padding-right: 25px;
      transition: all 0.3s ease;
      &:last-child {
        padding-bottom: 20px;
      }
    }
    .nav-link {
      color: #3d3d3d;
      font-weight: 500;
      font-size: 16px;
      padding: 10px 20px;
      border-radius: 5px;
      transition: all 0.3s ease;
      &:hover {
        background-color: rgba(9, 30, 66, 0.04);
        fill: rgba(9, 30, 66, 0.04);
        transition: all 0.3s ease;
        color: $primary-color;
      }
    }
    .foot-bar {
      position: absolute;
      bottom: 100px;
      left: 15px;
      width: 90%;
      padding-left: 25px;
      padding-right: 21px;
      text-align: center;
      z-index: 200;
      span {
        color: black;
        font-size: 15px;
      }
    }
    .active {
      background-color: rgba(9, 30, 66, 0.04);
      fill: rgba(9, 30, 66, 0.04);
      border-radius: 5px;
      padding-top: 8px;
      padding-bottom: 12px;
      color: $primary-color;
    }
  }

  .right-profile {
    padding-left: 0px;
    margin-top: 30px;
    .page-auto {
      width: 56%;
      margin: auto;
      .flex-container {
        width: 100%;
        margin: auto;
      }
      .left-side {
        float: left;
        display: flex;
        flex-direction: column;
      }
      .title-class-name {
        margin-top: 13px;
        margin-bottom: 4px;
        text-align: center;
        span {
          color: #3d3d3d;
          font-size: 15px;
        }
      }
      .title-school-name {
        width: 130px;
        justify-content: center;
        text-align: center;
        span {
          color: #3d3d3d;
          font-size: 15px;
        }
      }
      .title-general {
        font-size: 30px;
        font-weight: 500;
        color: $primary-color;
        padding-bottom: 30px;
        display: inline-block;
      }
      .image-logo {
        width: 130px;
        height: 130px;
        position: relative;
        border: 2px solid #eee;
        padding: 4px;
        border-radius: 50%;
        z-index: 3000;
        .image-user {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          object-fit: cover;
          object-position: top;
        }
        .pop-up {
          width: 100%;
          position: absolute;
          height: 100%;
          border-radius: 50%;
          top: 0px;
          left: 0px;
          opacity: 0;
          text-align: center;
          display: flex;
          justify-content: center;
          transition: opacity 0.3s;
          background-color: rgba(0, 0, 0, 0.5);
          i {
            margin: auto;
            font-size: 23px;
            color: white;
            cursor: pointer;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
        &:hover {
          .pop-up {
            opacity: 1;
            transition: opacity 0.3s;
          }
        }
      }
      .form-input {
        display: inline-block;
        width: 60%;
        margin-left: 28px;
        .title-personal {
          font-size: 18px;
          font-weight: 500;
          color: #3d3d3d;
          margin-bottom: 13px;
          display: inline-block;
        }
        .form-context {
          width: 100%;
          .ant-input-affix-wrapper .ant-input {
            border-radius: 0px;
          }
          .ant-select-selection {
            border-radius: 0px;
          }
          .ant-calendar-picker-input {
            border-radius: 0px;
          }
          .ant-calendar-panel {
            border-radius: 0px;
          }
          .btn-submit {
            margin-top: 10px !important;
            background-color: rgb(93, 233, 198) !important;
            border-radius: 0px !important;
            border: 0px !important;
            width: 120px !important;
            transition: 0.3s ease !important;
            padding: 7px !important;
            text-align: center !important;
            cursor: pointer !important;
            color: white !important;
            border: 1px solid rgb(93, 233, 198) !important;
            font-size: 15px !important;
            &:hover {
              background: none !important;
              transition: 0.3s ease !important;
              color: #3d3d3d !important;
              border: 1px solid rgb(93, 233, 198) !important;
            }
          }
        }
      }
    }
  }

  .content {
    .title-change-email {
      font-size: 18px;
      color: #3d3d3d;
      display: inline-block;
      margin-bottom: 10px;
    }
    .bottom-bit {
      margin-bottom: 14px;
    }
    .title-detail-email {
      font-size: 15px;
    }
    .in-one {
      margin-bottom: 25px;
    }
    .current {
      float: left;
    }
    .user {
      display: inline-block;
      margin-left: 7px;
      span {
        font-size: 15px;
        font-weight: bolder;
      }
    }
  }
  .btn-submit {
    margin-top: 10px;
    background-color: rgb(93, 233, 198);
    border-radius: 0px;
    border: 0px;
    margin-bottom: 25px;
    width: 120px;
    transition: 0.3s ease;
    padding: 6px;
    text-align: center;
    cursor: pointer;
    color: white;
    border: 1px solid rgb(93, 233, 198);
    font-size: 15px;
    &:hover {
      background: none;
      transition: 0.3s ease;
      color: #3d3d3d;
      border: 1px solid rgb(93, 233, 198);
    }
  }
  .padding-left0 {
    padding-left: 0px;
  }
  .padding-right0 {
    padding-right: 0px;
  }
  .ant-input-affix-wrapper .ant-input {
    border-radius: 0px;
  }
  .detail {
    p {
      font-size: 15px;
    }
  }
}
.file-upload {
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
.loading-gif {
  width: 100%;
  height: 100%;
  text-align: center;
  border-radius: 50%;
  img {
    height: 105px;
    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
  }
}

.ant-dropdown {
  z-index: 5000 !important;
}
.ant-message {
  z-index: 8000 !important;
}

.ant-select-dropdown {
  border-radius: 0px !important;
  .ant-select-dropdown-menu-item {
    &:first-child {
      border-radius: 0px !important;
    }
    &:last-child {
      border-radius: 0px !important;
    }
  }
}
.ant-calendar {
  border-radius: 0px !important;
}

.box-success {
  width: 75%;
  margin: auto;
  margin-top: 20px;
  padding-left: 0px;
  padding-right: 0px;
  .blog-image {
    background-color: $primary-color;
    padding-left: 0px;
    padding-right: 0px;
    text-align: center;
    padding-top: 40px;
    padding-bottom: 40px;
    img {
      margin: auto;
      height: 110px;
    }
  }
  .box-details {
    background-color: white;
    border: 1px solid #eee;
    border-top: 0px;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    .image-star {
      text-align: center;
      margin-bottom: 10px;
      img {
        height: 25px;
        margin: auto;
      }
    }
    .title {
      margin-bottom: 0px;
      text-align: center;
      .title-verified {
        margin: auto;
        font-size: 25px;
        color: $primary-color;
      }
    }
    .detail {
      text-align: center;
      margin-bottom: 5px;
      margin-top: 5px;
      p {
        margin: auto;
        padding-left: 20px;
        padding-right: 20px;
      }
    }
    .btn-submited {
      @extend .btn-submit;
      margin: auto;
      width: 120px;
      margin-top: 10px !important;
    }
  }
}
.ant-message .anticon {
  top: -3px !important;
}
.loading-spin {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(238, 238, 238, 0.753);
  z-index: 5000;
  text-align: center;
  transform: translate(-50%, -50%);
  .ant-spin {
    font-size: 18px;
  }
}
.btn-submit-form {
  margin-top: 6px;
  text-align: center;
  vertical-align: middle;
  cursor: pointer !important;
  .anticon-loading {
    position: relative;
    top: -3px;
    right: 0px;
  }
}
.anticon-loading {
  position: relative;
  top: -3px;
  right: 0px;
  span {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
