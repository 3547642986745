// color
$dark-color: #343a40;
$muted-color: #6c757d;
$white-color: #ffffff;
$error-color: #db0d4c;
// $primary-color: #35D098;
// $input-border-primary-color: #35D098;
$primary-color: #009533;
$secondary-color: #0095331c;
$input-border-primary-color: #009533;
// $gradient-primary-top-bottom: linear-gradient(to bottom, #30EA98, #3AB398);
// $gradient-primary-top-bottom-right: linear-gradient(to bottom right, #30EA98, #3AB398);
$gradient-primary-top-bottom: linear-gradient(to bottom, #009533, #009533);
$gradient-primary-top-bottom-right: linear-gradient(to bottom right, #009533, #009533);
$background-left-profile: rgb(244, 245, 247);
// Font

// Height

// Weight
