@import url("https://fonts.googleapis.com/css?family=Kantumruy|Ubuntu&display=swap");

* {
  font-family: "Ubuntu", "Kantumruy", sans-serif;
  line-height: 2em;
}

#root {
  height: 100%;
}

.content-center-screen {
  top: 50%;
  left: 50%;
  width: 100%;
  position: absolute;
  transform: translate(-50%, -50%);
}

:export {
  primaryColor: $primary-color;
}

.font-weight-bold {
  font-weight: 500 !important;
}

.text-primary {
  color: $primary-color !important;
}

.text-danger {
  color: $error-color !important;
}

// .text-shadow {
//   text-shadow: rgba(0, 0, 0, 0.25) 1px 1px 1px !important;
// }

.border-primary {
  border-color: $primary-color !important;
}

.border-danger {
  border-color: $error-color !important;
}

@for $pixel from 1 through 5 {
  .border-#{$pixel} {
    border: #{$pixel}px solid;
  }
}

@for $pixel from 1 through 5 {
  .border-bottom-#{$pixel} {
    border-bottom: #{$pixel}px solid $primary-color !important;
  }
}

@for $pixel from 1 through 50 {
  .font-#{$pixel} {
    font-size: #{$pixel}px !important;
  }
}

.bg-gradient-primary-top-bottom {
  background-image: $gradient-primary-top-bottom !important;
}

.bg-gradient-primary-top-bottom-right {
  background-image: $gradient-primary-top-bottom-right !important;
}

.bg-primary {
  background-color: $primary-color !important;
}

.bg-danger {
  background-color: #f7d7eb !important;
}

.box-shadow {
  box-shadow: $box-shadow-base !important;
}

.img-html-page {
  width: 100%;
}

.clear-fix {
  clear: both;
}
.icon-download-canvas {
  cursor: pointer;
}

.ant-layout-sider-children {
  .ant-menu-root {
    .ant-menu-item {
      span.pl-2 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.quiz-box {
  .ant-radio-wrapper {
    display: flex !important;
    white-space: inherit;
    height: auto !important;

    span.ant-radio {
      padding-top: 5px !important;
    }
  }
}

.checklist-form {
  .ant-form-item-control {
    line-height: 15px !important;
    .ant-form-explain {
      margin-bottom: 10px;
    }
  }
}

.enter-class-code {
  h6 {
    font-weight: 350;
  }
}

.right-dropdown {
  li {
    a.active {
      font-weight: bold;
    }
  }
}

.html-element {
  b,
  strong {
    font-weight: 500;
  }
}

.demo-wrapper {
  width: 100%;
  border: 1px dashed #35d098;
  border-radius: 5px;
  margin-bottom: 20px;
}

.demo-editor {
  padding: 0 1rem !important;
  min-height: 150px;
}

.navbar .custom-navbar-brand {
  margin: 0rem;
  padding: 0rem;
  // display: flex;
  // align-items: flex-end;
  position: relative;

  .custom-navbar-brand {
    position: absolute;
    width: 200px;
    display: flex;
    align-items: flex-end;
    position: relative;
  }
}

.text-height {
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  bottom: 20px;
  width: 95%;
}

.player-wrapper {
  iframe {
    background-color: #fff;
  }

  div {
    background: #fff !important;
  }
}

// .badge-show-name {
//   position: absolute;
//   right: 10px;
//   top: 10px;
// }

.name-button {
  border-radius: 5px !important;
  height: 30px !important;
}

// .custom-canvas-box {
//   &:hover {
//     background: red;
//   }
// }

.navbar-nav {
  .nav-item {
    .menu-item {
      &:hover {
        color: $primary-color !important;
      }
    }
  }
}

.ant-breadcrumb {
  font-size: 15px !important;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 2em;
}

.image-responsive {
  max-width: 100%;
}
.mtr98 svg {
  margin-top: -9.2px !important;
  margin-right: 8px !important;
}

.ant-layout-sider-children .ant-menu-item a {
  line-height: 2.45em;
}

.padding-username {
  padding: 12px 14px !important;
}

.session-title-menu {
  // color: #fff;
  height: 60px;
  font-size: 22px;
  position: relative;
  border-bottom: 2px solid #fff;
}

.back-arrow-link {
  background-color: $primary-color;
  padding-top: 11px;
  padding-bottom: 24px;
  height: 60px;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 20px;
  display: none;
}

.back-arrow-link img {
  width: 10px;
  height: auto;
  margin-top: 10px;
}

.btn-toggle-menu {
  width: 50px !important;
  height: 40px !important;
  border-radius: 4px !important;
  margin-right: 12px !important;
  margin-top: 8px !important;
  // background-color: #4aaf6c2e !important;
  background-color: $primary-color !important;
  border: 0px !important;
}

.btn-toggle-menu i {
  color: #fff !important;
  font-size: 20px !important;
}

.text-align-right {
  text-align: right;
}

.back-button-history {
  background-color: #fff;
  border: 1px dashed #d9d9d9;
  text-align: center;
  padding: 0px 10px;
  margin-right: 10px;
  cursor: pointer;
}

.arrow-left-back {
  margin-right: 5px;
  cursor: pointer;
}

.arrow-left-back svg {
  margin-top: -5px;
}

.text-back-label {
  font-size: 14px;
  margin-left: 5px;
  cursor: pointer;
}

.team-select {
  .ant-select-focused {
    .ant-select-selection__placeholder {
      visibility: hidden;
    }

    .ant-select-selection--multiple .ant-select-search--inline .ant-select-search__field {
      width: 100% !important;
    }
  }
}

// .canvas-layout {
//   .custom-canvas-box-disabled {
//     pointer-events: none;
//   }
// }

.ant-badge .anticon-notification svg {
  margin-top: -5px !important;
}

.about-us-content {
  p {
    span {
      font-size: 16px;

      strong {
        font-size: 22px;
      }
    }
  }
}

.about-us-support {
  color: #333;
  & > div {
    margin-bottom: 30px;
  }
  p {
    font-size: 20px;
    margin-bottom: 0px;
  }
  img {
    height: 100px;

    &.impact-hub {
      height: 83px;
      margin-right: 20px;
    }

    &.smart {
      height: 83px;
    }

    &.moe {
      margin-top: -10px;
      margin-right: 5px;
    }

    &.mptc {
      height: 115px;
      // margin-top: 15px;
    }

    &.cbrd {
      height: 90px;
    }

    &.edemy {
      height: 80px;
    }
  }
}

span.ml-2.d-md-none.d-lg-none {
  color: $primary-color;
}

a.text-mute.menu-item.active {
  background: #e8e8e8;
  display: block;
  padding: 5px;
  border-radius: 50%;
}

@media only screen and (max-width: 600px) {
  .container,
  .container-fluid,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    width: 100%;
    padding-right: 5px;
    padding-left: 5px;
    margin-right: auto;
    margin-left: auto;
  }

  .navbar-collapse {
    padding: 0px 15px;

    .nav-item {
      width: 100%;
    }
  }

  a.text-mute.menu-item.active {
    background: $secondary-color;
    padding: 5px;
    border-radius: 0px;
  }
}
