@media print {
  body {
    .wrapper-body {
      .wrapper-content {
        .canvas-layout {
          .list-team,
          .icon-download-canvas,
          .show-history {
            display: none;
          }

          .mb-5,
          .my-5 {
            margin-bottom: 0rem !important;
          }

          .d-flex.show-history {
            display: none !important;
          }
        }
      }
    }
    .footer-container {
      display: none !important;
    }
  }
}

@page {
  size: auto;
}
