$blue-1: #e6f7ff;
$blue-2: #bae7ff;
$blue-3: #91d5ff;
$blue-4: #69c0ff;
$blue-5: #40a9ff;
$blue-6: #1890ff;
$blue-7: #096dd9;
$blue-8: #0050b3;
$blue-9: #003a8c;
$blue-10: #002766;
$purple-1: #f9f0ff;
$purple-2: #efdbff;
$purple-3: #d3adf7;
$purple-4: #b37feb;
$purple-5: #9254de;
$purple-6: #722ed1;
$purple-7: #531dab;
$purple-8: #391085;
$purple-9: #22075e;
$purple-10: #120338;
$cyan-1: #e6fffb;
$cyan-2: #b5f5ec;
$cyan-3: #87e8de;
$cyan-4: #5cdbd3;
$cyan-5: #36cfc9;
$cyan-6: #13c2c2;
$cyan-7: #08979c;
$cyan-8: #006d75;
$cyan-9: #00474f;
$cyan-10: #002329;
$green-1: #f6ffed;
$green-2: #d9f7be;
$green-3: #b7eb8f;
$green-4: #95de64;
$green-5: #73d13d;
$green-6: #52c41a;
$green-7: #389e0d;
$green-8: #237804;
$green-9: #135200;
$green-10: #092b00;
$magenta-1: #fff0f6;
$magenta-2: #ffd6e7;
$magenta-3: #ffadd2;
$magenta-4: #ff85c0;
$magenta-5: #f759ab;
$magenta-6: #eb2f96;
$magenta-7: #c41d7f;
$magenta-8: #9e1068;
$magenta-9: #780650;
$magenta-10: #520339;
$pink-1: #fff0f6;
$pink-2: #ffd6e7;
$pink-3: #ffadd2;
$pink-4: #ff85c0;
$pink-5: #f759ab;
$pink-6: #eb2f96;
$pink-7: #c41d7f;
$pink-8: #9e1068;
$pink-9: #780650;
$pink-10: #520339;
$red-1: #fff1f0;
$red-2: #ffccc7;
$red-3: #ffa39e;
$red-4: #ff7875;
$red-5: #ff4d4f;
$red-6: #f5222d;
$red-7: #cf1322;
$red-8: #a8071a;
$red-9: #820014;
$red-10: #5c0011;
$orange-1: #fff7e6;
$orange-2: #ffe7ba;
$orange-3: #ffd591;
$orange-4: #ffc069;
$orange-5: #ffa940;
$orange-6: #fa8c16;
$orange-7: #d46b08;
$orange-8: #ad4e00;
$orange-9: #873800;
$orange-10: #612500;
$yellow-1: #feffe6;
$yellow-2: #ffffb8;
$yellow-3: #fffb8f;
$yellow-4: #fff566;
$yellow-5: #ffec3d;
$yellow-6: #fadb14;
$yellow-7: #d4b106;
$yellow-8: #ad8b00;
$yellow-9: #876800;
$yellow-10: #614700;
$volcano-1: #fff2e8;
$volcano-2: #ffd8bf;
$volcano-3: #ffbb96;
$volcano-4: #ff9c6e;
$volcano-5: #ff7a45;
$volcano-6: #fa541c;
$volcano-7: #d4380d;
$volcano-8: #ad2102;
$volcano-9: #871400;
$volcano-10: #610b00;
$geekblue-1: #f0f5ff;
$geekblue-2: #d6e4ff;
$geekblue-3: #adc6ff;
$geekblue-4: #85a5ff;
$geekblue-5: #597ef7;
$geekblue-6: #2f54eb;
$geekblue-7: #1d39c4;
$geekblue-8: #10239e;
$geekblue-9: #061178;
$geekblue-10: #030852;
$lime-1: #fcffe6;
$lime-2: #f4ffb8;
$lime-3: #eaff8f;
$lime-4: #d3f261;
$lime-5: #bae637;
$lime-6: #a0d911;
$lime-7: #7cb305;
$lime-8: #5b8c00;
$lime-9: #3f6600;
$lime-10: #254000;
$gold-1: #fffbe6;
$gold-2: #fff1b8;
$gold-3: #ffe58f;
$gold-4: #ffd666;
$gold-5: #ffc53d;
$gold-6: #faad14;
$gold-7: #d48806;
$gold-8: #ad6800;
$gold-9: #874d00;
$gold-10: #613400;
$preset-colors: pink, magenta, red, volcano, orange, yellow, gold, cyan, lime, green, blue, geekblue, purple;
$ant-prefix: ant;
$html-selector: html;
$primary-color: #009533;
$info-color: #1890ff;
$success-color: #52c41a;
$processing-color: #1890ff;
$error-color: #db0d4c;
$highlight-color: #f5222d;
$warning-color: #faad14;
$normal-color: #d9d9d9;
$white: #fff;
$black: #000;
$primary-1: #bed4c2;
$primary-2: #7fc78f;
$primary-3: #59ba72;
$primary-4: #37ad59;
$primary-5: #1aa144;
$primary-6: #009533;
$primary-7: #006e2a;
$primary-8: #00471e;
$primary-9: #00210f;
$primary-10: #000000;
$body-background: #fff;
$component-background: #fff;
$font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
$code-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
$text-color: rgba(0, 0, 0, 0.65);
$text-color-secondary: rgba(0, 0, 0, 0.45);
$text-color-inverse: #fff;
$icon-color: inherit;
$icon-color-hover: rgba(0, 0, 0, 0.75);
$heading-color: rgba(0, 0, 0, 0.85);
$heading-color-dark: #ffffff;
$text-color-dark: rgba(255, 255, 255, 0.85);
$text-color-secondary-dark: rgba(255, 255, 255, 0.65);
$text-selection-bg: #009533;
$font-variant-base: tabular-nums;
$font-feature-settings-base: 'tnum';
$font-size-base: 14px;
$font-size-lg: 16px;
$font-size-sm: 12px;
$heading-1-size: 38px;
$heading-2-size: 30px;
$heading-3-size: 24px;
$heading-4-size: 20px;
$line-height-base: 1.5;
$border-radius-base: 20px;
$border-radius-sm: 2px;
$padding-lg: 24px;
$padding-md: 16px;
$padding-sm: 12px;
$padding-xs: 8px;
$control-padding-horizontal: 12px;
$control-padding-horizontal-sm: 8px;
$item-active-bg: #bed4c2;
$item-hover-bg: #bed4c2;
$iconfont-css-prefix: anticon;
$link-color: #009533;
$link-hover-color: #1aa144;
$link-active-color: #006e2a;
$link-decoration: none;
$link-hover-decoration: none;
$ease-base-out: cubic-bezier(0.7, 0.3, 0.1, 1);
$ease-base-in: cubic-bezier(0.9, 0, 0.3, 0.7);
$ease-out: cubic-bezier(0.215, 0.61, 0.355, 1);
$ease-in: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$ease-in-out: cubic-bezier(0.645, 0.045, 0.355, 1);
$ease-out-back: cubic-bezier(0.12, 0.4, 0.29, 1.46);
$ease-in-back: cubic-bezier(0.71, -0.46, 0.88, 0.6);
$ease-in-out-back: cubic-bezier(0.71, -0.46, 0.29, 1.46);
$ease-out-circ: cubic-bezier(0.08, 0.82, 0.17, 1);
$ease-in-circ: cubic-bezier(0.6, 0.04, 0.98, 0.34);
$ease-in-out-circ: cubic-bezier(0.78, 0.14, 0.15, 0.86);
$ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1);
$ease-in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$ease-in-out-quint: cubic-bezier(0.86, 0, 0.07, 1);
$border-color-base: #d9d9d9;
$border-color-split: #e8e8e8;
$border-color-inverse: #fff;
$border-width-base: 1px;
$border-style-base: solid;
$outline-blur-size: 0;
$outline-width: 2px;
$outline-color: #009533;
$background-color-light: #fafafa;
$background-color-base: #f5f5f5;
$disabled-color: rgba(0, 0, 0, 0.25);
$disabled-bg: #f5f5f5;
$disabled-color-dark: rgba(255, 255, 255, 0.35);
$shadow-color: rgba(0, 0, 0, 0.15);
$shadow-color-inverse: #fff;
$box-shadow-base: 0 2px 4px 0 rgba(38, 35, 66, 0.25);
$shadow-1-up: 0 -2px 8px rgba(0, 0, 0, 0.15);
$shadow-1-down: 0 2px 8px rgba(0, 0, 0, 0.15);
$shadow-1-left: -2px 0 8px rgba(0, 0, 0, 0.15);
$shadow-1-right: 2px 0 8px rgba(0, 0, 0, 0.15);
$shadow-2: 0 4px 12px rgba(0, 0, 0, 0.15);
$btn-font-weight: 400;
$btn-border-radius-base: 20px;
$btn-border-radius-sm: 20px;
$btn-border-width: 1px;
$btn-border-style: solid;
$btn-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
$btn-primary-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
$btn-text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
$btn-primary-color: #fff;
$btn-primary-bg: #009533;
$btn-default-color: rgba(0, 0, 0, 0.65);
$btn-default-bg: #fff;
$btn-default-border: #d9d9d9;
$btn-danger-color: #fff;
$btn-danger-bg: #e83363;
$btn-danger-border: #e83363;
$btn-disable-color: rgba(0, 0, 0, 0.25);
$btn-disable-bg: #f5f5f5;
$btn-disable-border: #d9d9d9;
$btn-padding-base: 0 16px;
$btn-font-size-lg: 16px;
$btn-font-size-sm: 14px;
$btn-padding-lg: 0 16px;
$btn-padding-sm: 0 7px;
$btn-height-base: 40px;
$btn-height-lg: 40px;
$btn-height-sm: 24px;
$btn-circle-size: 40px;
$btn-circle-size-lg: 40px;
$btn-circle-size-sm: 24px;
$btn-square-size: 40px;
$btn-square-size-lg: 40px;
$btn-square-size-sm: 24px;
$btn-group-border: #1aa144;
$checkbox-size: 16px;
$checkbox-color: #009533;
$checkbox-check-color: #fff;
$checkbox-border-width: 1px;
$descriptions-bg: #fafafa;
$dropdown-selected-color: #009533;
$empty-font-size: 14px;
$radio-size: 16px;
$radio-dot-color: #009533;
$radio-button-bg: #fff;
$radio-button-checked-bg: #fff;
$radio-button-color: rgba(0, 0, 0, 0.65);
$radio-button-hover-color: #1aa144;
$radio-button-active-color: #006e2a;
$screen-xs: 480px;
$screen-xs-min: 480px;
$screen-sm: 576px;
$screen-sm-min: 576px;
$screen-md: 768px;
$screen-md-min: 768px;
$screen-lg: 992px;
$screen-lg-min: 992px;
$screen-xl: 1200px;
$screen-xl-min: 1200px;
$screen-xxl: 1600px;
$screen-xxl-min: 1600px;
$screen-xs-max: 575px;
$screen-sm-max: 767px;
$screen-md-max: 991px;
$screen-lg-max: 1199px;
$screen-xl-max: 1599px;
$grid-columns: 24;
$grid-gutter-width: 0;
$layout-body-background: inherit;
$layout-header-background: #001529;
$layout-footer-background: inherit;
$layout-header-height: 64px;
$layout-header-padding: 0 50px;
$layout-footer-padding: 24px 50px;
$layout-sider-background: #001529;
$layout-trigger-height: 48px;
$layout-trigger-background: #002140;
$layout-trigger-color: #fff;
$layout-zero-trigger-width: 36px;
$layout-zero-trigger-height: 42px;
$layout-sider-background-light: #fff;
$layout-trigger-background-light: #fff;
$layout-trigger-color-light: rgba(0, 0, 0, 0.65);
$zindex-badge: 1;
$zindex-table-fixed: 1;
$zindex-affix: 10;
$zindex-back-top: 10;
$zindex-picker-panel: 10;
$zindex-popup-close: 10;
$zindex-modal: 1000;
$zindex-modal-mask: 1000;
$zindex-message: 1010;
$zindex-notification: 1010;
$zindex-popover: 1030;
$zindex-dropdown: 1050;
$zindex-picker: 1050;
$zindex-tooltip: 1060;
$animation-duration-slow: 0.3s;
$animation-duration-base: 0.2s;
$animation-duration-fast: 0.1s;
$collapse-panel-border-radius: 20px;
$dropdown-vertical-padding: 5px;
$dropdown-edge-child-vertical-padding: 4px;
$dropdown-font-size: 14px;
$dropdown-line-height: 22px;
$label-required-color: #f5222d;
$label-color: rgba(0, 0, 0, 0.85);
$form-warning-input-bg: #fff;
$form-item-margin-bottom: 16px;
$form-item-trailing-colon: true;
$form-vertical-label-padding: 0 0 8px;
$form-vertical-label-margin: 0;
$form-item-label-colon-margin-right: 8px;
$form-item-label-colon-margin-left: 2px;
$form-error-input-bg: #fff;
$input-height-base: 40px;
$input-height-lg: 40px;
$input-height-sm: 24px;
$input-padding-horizontal: 11px;
$input-padding-horizontal-base: 11px;
$input-padding-horizontal-sm: 7px;
$input-padding-horizontal-lg: 11px;
$input-padding-vertical-base: 4px;
$input-padding-vertical-sm: 1px;
$input-padding-vertical-lg: 6px;
$input-placeholder-color: #bfbfbf;
$input-color: rgba(0, 0, 0, 0.65);
$input-border-color: #009533;
$input-bg: #fff;
$input-number-hover-border-color: #1aa144;
$input-number-handler-active-bg: #f4f4f4;
$input-number-handler-hover-bg: #1aa144;
$input-number-handler-bg: #fff;
$input-number-handler-border-color: #d9d9d9;
$input-addon-bg: #fafafa;
$input-hover-border-color: #1aa144;
$input-disabled-bg: #f5f5f5;
$input-outline-offset: 0 0;
$select-border-color: #009533;
$select-item-selected-font-weight: 600;
$select-dropdown-bg: #fff;
$select-dropdown-vertical-padding: 5px;
$select-dropdown-edge-child-vertical-padding: 4px;
$select-dropdown-font-size: 14px;
$select-dropdown-line-height: 22px;
$select-item-selected-bg: #fafafa;
$select-item-active-bg: #bed4c2;
$select-background: #fff;
$cascader-dropdown-vertical-padding: 5px;
$cascader-dropdown-edge-child-vertical-padding: 4px;
$cascader-dropdown-font-size: 14px;
$cascader-dropdown-line-height: 22px;
$anchor-border-color: #e8e8e8;
$tooltip-max-width: 250px;
$tooltip-color: #fff;
$tooltip-bg: rgba(0, 0, 0, 0.75);
$tooltip-arrow-width: 5px;
$tooltip-distance: 8px;
$tooltip-arrow-color: rgba(0, 0, 0, 0.75);
$popover-bg: #fff;
$popover-color: rgba(0, 0, 0, 0.65);
$popover-min-width: 177px;
$popover-arrow-width: 6px;
$popover-arrow-color: #fff;
$popover-arrow-outer-color: #fff;
$popover-distance: 10px;
$modal-body-padding: 24px;
$modal-header-bg: #fff;
$modal-heading-color: rgba(0, 0, 0, 0.85);
$modal-footer-bg: transparent;
$modal-footer-border-color-split: #e8e8e8;
$modal-mask-bg: rgba(0, 0, 0, 0.45);
$progress-default-color: #1890ff;
$progress-remaining-color: #f5f5f5;
$progress-text-color: rgba(0, 0, 0, 0.65);
$progress-radius: 100px;
$menu-inline-toplevel-item-height: 40px;
$menu-item-height: 40px;
$menu-collapsed-width: 80px;
$menu-bg: #fff;
$menu-popup-bg: #fff;
$menu-item-color: rgba(0, 0, 0, 0.65);
$menu-highlight-color: #009533;
$menu-item-active-bg: #bed4c2;
$menu-item-active-border-width: 3px;
$menu-item-group-title-color: rgba(0, 0, 0, 0.45);
$menu-icon-size: 14px;
$menu-icon-size-lg: 16px;
$menu-item-vertical-margin: 4px;
$menu-item-font-size: 14px;
$menu-item-boundary-margin: 8px;
$menu-dark-color: rgba(255, 255, 255, 0.65);
$menu-dark-bg: #001529;
$menu-dark-arrow-color: #fff;
$menu-dark-submenu-bg: #000c17;
$menu-dark-highlight-color: #fff;
$menu-dark-item-active-bg: #009533;
$menu-dark-selected-item-icon-color: #fff;
$menu-dark-selected-item-text-color: #fff;
$menu-dark-item-hover-bg: transparent;
$spin-dot-size-sm: 14px;
$spin-dot-size: 20px;
$spin-dot-size-lg: 32px;
$table-header-bg: #fafafa;
$table-header-color: rgba(0, 0, 0, 0.85);
$table-header-sort-bg: #f5f5f5;
$table-body-sort-bg: rgba(0, 0, 0, 0.01);
$table-row-hover-bg: #bed4c2;
$table-selected-row-color: inherit;
$table-selected-row-bg: #fafafa;
$table-body-selected-sort-bg: #fafafa;
$table-selected-row-hover-bg: #fafafa;
$table-expanded-row-bg: #fbfbfb;
$table-padding-vertical: 16px;
$table-padding-horizontal: 16px;
$table-border-radius-base: 20px;
$table-footer-bg: #fafafa;
$table-footer-color: rgba(0, 0, 0, 0.85);
$table-header-bg-sm: transparent;
$tag-default-bg: #fafafa;
$tag-default-color: rgba(0, 0, 0, 0.65);
$tag-font-size: 12px;
$time-picker-panel-column-width: 56px;
$time-picker-panel-width: 168px;
$time-picker-selected-bg: #f5f5f5;
$carousel-dot-width: 16px;
$carousel-dot-height: 3px;
$carousel-dot-active-width: 24px;
$badge-height: 20px;
$badge-dot-size: 6px;
$badge-font-size: 12px;
$badge-font-weight: normal;
$badge-status-size: 6px;
$badge-text-color: #fff;
$rate-star-color: #fadb14;
$rate-star-bg: #e8e8e8;
$card-head-color: rgba(0, 0, 0, 0.85);
$card-head-background: transparent;
$card-head-padding: 16px;
$card-inner-head-padding: 12px;
$card-padding-base: 24px;
$card-actions-background: #fafafa;
$card-skeleton-bg: #cfd8dc;
$card-background: #fff;
$card-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);
$card-radius: 2px;
$comment-padding-base: 16px 0;
$comment-nest-indent: 44px;
$comment-font-size-base: 14px;
$comment-font-size-sm: 12px;
$comment-author-name-color: rgba(0, 0, 0, 0.45);
$comment-author-time-color: #ccc;
$comment-action-color: rgba(0, 0, 0, 0.45);
$comment-action-hover-color: #595959;
$tabs-card-head-background: #fafafa;
$tabs-card-height: 40px;
$tabs-card-active-color: #009533;
$tabs-title-font-size: 14px;
$tabs-title-font-size-lg: 16px;
$tabs-title-font-size-sm: 14px;
$tabs-ink-bar-color: #009533;
$tabs-bar-margin: 0 0 16px 0;
$tabs-horizontal-margin: 0 32px 0 0;
$tabs-horizontal-padding: 12px 16px;
$tabs-horizontal-padding-lg: 16px;
$tabs-horizontal-padding-sm: 8px 16px;
$tabs-vertical-padding: 8px 24px;
$tabs-vertical-margin: 0 0 16px 0;
$tabs-scrolling-size: 32px;
$tabs-highlight-color: #009533;
$tabs-hover-color: #1aa144;
$tabs-active-color: #006e2a;
$tabs-card-gutter: 2px;
$tabs-card-tab-active-border-top: 2px solid transparent;
$back-top-color: #fff;
$back-top-bg: rgba(0, 0, 0, 0.45);
$back-top-hover-bg: rgba(0, 0, 0, 0.65);
$avatar-size-base: 32px;
$avatar-size-lg: 40px;
$avatar-size-sm: 24px;
$avatar-font-size-base: 18px;
$avatar-font-size-lg: 24px;
$avatar-font-size-sm: 14px;
$avatar-bg: #ccc;
$avatar-color: #fff;
$avatar-border-radius: 20px;
$switch-height: 22px;
$switch-sm-height: 16px;
$switch-sm-checked-margin-left: -13px;
$switch-disabled-opacity: 0.4;
$switch-color: #009533;
$switch-shadow-color: rgba(0, 35, 11, 0.2);
$pagination-item-size: 32px;
$pagination-item-size-sm: 24px;
$pagination-font-family: Arial;
$pagination-font-weight-active: 500;
$pagination-item-bg-active: #fff;
$page-header-padding: 24px;
$page-header-padding-vertical: 16px;
$page-header-padding-breadcrumb: 12px;
$page-header-back-color: #000;
$breadcrumb-base-color: rgba(0, 0, 0, 0.45);
$breadcrumb-last-item-color: rgba(0, 0, 0, 0.65);
$breadcrumb-font-size: 14px;
$breadcrumb-icon-font-size: 14px;
$breadcrumb-link-color: rgba(0, 0, 0, 0.45);
$breadcrumb-link-color-hover: #1aa144;
$breadcrumb-separator-color: rgba(0, 0, 0, 0.45);
$breadcrumb-separator-margin: 0 8px;
$slider-margin: 14px 6px 10px;
$slider-rail-background-color: #f5f5f5;
$slider-rail-background-color-hover: #e1e1e1;
$slider-track-background-color: #59ba72;
$slider-track-background-color-hover: #37ad59;
$slider-handle-border-width: 2px;
$slider-handle-background-color: #fff;
$slider-handle-color: #59ba72;
$slider-handle-color-hover: #37ad59;
$slider-handle-color-focus: #33aa5c;
$slider-handle-color-focus-shadow: rgba(0, 149, 51, 0.2);
$slider-handle-color-tooltip-open: #009533;
$slider-handle-shadow: 0;
$slider-dot-border-color: #e8e8e8;
$slider-dot-border-color-active: #80ca99;
$slider-disabled-color: rgba(0, 0, 0, 0.25);
$slider-disabled-background-color: #fff;
$tree-title-height: 24px;
$tree-child-padding: 18px;
$tree-directory-selected-color: #fff;
$tree-directory-selected-bg: #009533;
$tree-node-hover-bg: #bed4c2;
$tree-node-selected-bg: #7fc78f;
$collapse-header-padding: 12px 16px;
$collapse-header-padding-extra: 40px;
$collapse-header-bg: #fafafa;
$collapse-content-padding: 16px;
$collapse-content-bg: #fff;
$skeleton-color: #f2f2f2;
$transfer-header-height: 40px;
$transfer-disabled-bg: #f5f5f5;
$transfer-list-height: 200px;
$message-notice-content-padding: 10px 16px;
$wave-animation-width: 6px;
$alert-success-border-color: #b7eb8f;
$alert-success-bg-color: #f6ffed;
$alert-success-icon-color: #52c41a;
$alert-info-border-color: #91d5ff;
$alert-info-bg-color: #e6f7ff;
$alert-info-icon-color: #1890ff;
$alert-warning-border-color: #ffe58f;
$alert-warning-bg-color: #fffbe6;
$alert-warning-icon-color: #faad14;
$alert-error-border-color: #ff8aa1;
$alert-error-bg-color: #ffe6e9;
$alert-error-icon-color: #db0d4c;
$list-header-background: transparent;
$list-footer-background: transparent;
$list-empty-text-padding: 16px;
$list-item-padding: 12px 0;
$list-item-meta-margin-bottom: 16px;
$list-item-meta-avatar-margin-right: 16px;
$list-item-meta-title-margin-bottom: 12px;
$statistic-title-font-size: 14px;
$statistic-content-font-size: 24px;
$statistic-unit-font-size: 16px;
$statistic-font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
$drawer-header-padding: 16px 24px;
$drawer-body-padding: 24px;
$timeline-width: 2px;
$timeline-color: #e8e8e8;
$timeline-dot-border-width: 2px;
$timeline-dot-color: #009533;
$timeline-dot-bg: #fff;
$typography-title-font-weight: 600;
$typography-title-margin-top: 1.2em;
$typography-title-margin-bottom: 0.5em;
