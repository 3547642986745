/* Large desktop */
@media (min-width: 991px) and (max-width: 1300px) {
  .wrapper-body {
    .mail-page-verify {
      .box-verify {
        width: 45%;
      }
    }

    .main-width {
      .left-profile {
        .nav-item {
          padding-left: 10px;
          padding-right: 10px;
        }
        .profile-left {
          .foot-bar {
            left: 0px;
            width: 100%;
            padding-left: 15px;
            padding-right: 15px;
            text-align: center;
            span {
              margin: auto;
            }
          }
        }
      }
    }
  }
}
/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {
  .wrapper-body {
    .mail-page-verify {
      .box-verify {
        width: 53%;
        .image-verify {
          img {
            height: 100px;
          }
        }
      }
    }
    .main-width {
      .menu-nabar {
        display: block;
      }
      .left-profile {
        display: none;
      }
      .right-profile {
        margin: auto;
        margin-top: 60px;
        .page-auto {
          width: 60%;
          .form-input {
            width: 60%;
          }
        }
        .page-email {
          width: 75%;
        }
        .page-security {
          width: 80%;
        }
        .box-success {
          margin-top: 10px;
          width: 85%;
        }
      }
    }
  }
}
/* landscape phone */
@media (min-width: 480px) and (max-width: 767px) {
  .footer-container {
    div.col-12 {
      text-align: center !important;
    }
  }
  .wrapper-body {
    .mail-page-verify {
      .box-verify {
        width: 68%;
        .image-verify {
          img {
            height: 100px;
          }
        }
        .title-mail-verify {
          font-size: 17px;
        }
        .detail {
          p {
            font-size: 16px;
          }
        }
      }
    }
    .main-width {
      .menu-nabar {
        display: block;
      }
      .left-profile {
        display: none;
      }
      .right-profile {
        margin: auto;
        margin-top: 10px;
        .page-auto {
          width: 80%;
          .form-input {
            width: 60%;
          }
          .title-general {
            margin: auto;
            width: 100%;
            text-align: center;
          }
          .title-school-name {
            width: 100%;
            text-align: center;
            margin: auto;
            margin-bottom: 17px;
          }
          .flex-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            .left-side {
              .image-logo {
                margin: -20px auto;
                text-align: center;
                margin-bottom: -2px;
              }
            }
            .form-input {
              width: 80%;
              margin: auto;
              .btn-submit {
                margin-bottom: 70px;
              }
            }
          }
        }
        .page-email {
          width: 85%;
          margin: 10px auto;
          .content {
            width: 80%;
            margin: auto;
            .user {
              margin-left: 2px;
            }
          }
        }
        .page-security {
          width: 75%;
          margin: 50px auto;
        }
        .box-success {
          margin-top: -30px !important;
          width: 73%;
        }
      }
    }
  }
}
/* Portrait phone */
@media (max-width: 479px) {
  .footer-container {
    div.col-12 {
      text-align: center !important;
    }
  }
  .wrapper-body {
    .mail-page-verify {
      .box-verify {
        width: 95%;
        margin-top: 95px;
        .image-verify {
          margin-bottom: 25px;
          padding-top: 45px;
          img {
            height: 100px;
          }
        }
        .title-mail-verify {
          font-size: 15px;
          width: 100%;
        }
        .detail {
          margin-bottom: 0px;
          padding-left: 5px;
          padding-right: 5px;
          p {
            font-size: 15px;
          }
        }
        .btn-back {
          margin-bottom: 50px;
        }
      }
    }
    .main-width {
      .padding-left0 {
        padding-right: 0px;
        padding-left: 0px;
      }
      .menu-nabar {
        display: block;
      }
      .left-profile {
        display: none;
      }
      .right-profile {
        margin: auto;
        margin-top: 10px;
        padding-right: 0px;
        .page-security {
          .content {
            margin-top: -15px;
          }
        }
        .page-auto {
          width: 85%;
          .form-input {
            width: 60%;
          }
          .title-general {
            margin: auto;
            width: 100%;
            text-align: center;
          }
          .title-school-name {
            width: 100%;
            text-align: center;
            margin: auto;
            margin-bottom: 17px;
          }
          .content {
            .user {
              margin-left: 2px;
            }
          }
          .in-one {
            margin-bottom: 17px;
          }
          .flex-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            .left-side {
              .image-logo {
                margin: -20px auto;
                text-align: center;
                margin-bottom: -2px;
              }
            }
            .form-input {
              width: 80%;
              margin: auto;
              .btn-submit {
                margin-bottom: 70px;
              }
            }
          }
        }
        .box-success {
          margin-top: 50px;
          width: 95%;
          .blog-image {
            .image-box {
              img {
                height: 90px;
              }
            }
          }
          .box-details {
            .title {
              span {
                font-size: 19px;
              }
            }
          }
        }
      }
    }
  }
}
