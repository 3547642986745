.mail-page-verify{
  // background-color:$primary-color;
  .box-verify{
    width:38%;
    border-radius: 15px;
    margin:auto;
    margin-top:60px;
    text-align: center;
    border:1px solid $primary-color;
    .image-verify{
      margin:auto;
      margin-bottom:28px;
      padding-top:80px;
      img{
        height:105px;
      }
    }
    .title-mail-verify{
      font-size:18px;
      color:black;
    }
    .detail{
      margin:auto;
      padding-left:35px;
      padding-right:35px;
      color:black;
      margin-top:5px;
      margin-bottom:15px;
      p{
        font-size:17px;
      }
    }
    .btn-back{
      width:130px;
      margin:auto;
      background-color:$primary-color;
      border-radius: 25px;
      text-align: center;
      color:$primary-color;
      padding:5px;
      border:2px solid $primary-color;
      color:white;
      margin-top:10px;
      cursor: pointer;
      margin-bottom:90px;
      display: inline-block;
      transition: 0.3s ease;
      display: flex;
      justify-content: center; 
      align-items: center; 
      span{
        font-size:16px;
      }
      &:hover{
          background:none;
          border:2px solid $primary-color;
          transition: 0.3s ease;
        span{
          color:$primary-color;
        }
      }
    }
  }
}
