.footer-container {
  border-top: 2px solid $secondary-color;
  // padding-top: 20px;
  // padding-bottom: 20px;
  font-size: 13px;

  a {
    color: $muted-color;
  }
}
